.logs {
  margin: 20px;
}

.log {
  overflow-wrap: anywhere;
  border: 2px black solid;
  border-radius: 25px;
  padding: 20px;
  margin: 20px 0px;
}

.log-date {
  text-decoration: underline;
}

.book-title {
  margin: 20px;
}

.my-books {
  float: right;
  padding: 10px;
  margin: 0px 10px 10px 10px;
}

.delete-book {
  margin: 0 20px 20px 20px;
}