label {
  display: block;
}

fieldset {
  border: none;
  padding: 0;
}

.loginPasswordError, .loginEmailError {
  color: red;
  font-size: 12px;
}

.releaseVersion {
  font-size: 8px;
}

h1 {
  display: inline;
}